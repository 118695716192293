import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'


import Layout from "../components/layout"
import { graphql } from "gatsby"

class ProgettoTemplate extends React.Component {

  render() { 
    const { titolo, durataDelProgetto, responsabileDelProgetto, 
      capofilaProgetto, testo, altriEntiPartners }  = this.props.data.contentfulProgetto;

    const breadcrumb = [
        {"title":"Progetti","link":"/progetti/"},
        {"title":`${titolo}`}];
    

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title={titolo}/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <article className="progetto">
            <h1 className="progetto__name">{titolo}</h1>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    {!!testo &&
                      <div className="progetto__text" dangerouslySetInnerHTML={{ __html: testo.childMarkdownRemark.html }}>

                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {!!capofilaProgetto &&
                  <div className="webcontent">
                    <h2 className="webcontent__title">Capofila del Progetto</h2>
                    <div dangerouslySetInnerHTML={{ __html: capofilaProgetto.childMarkdownRemark.html }}>
                    </div>
                  </div>
                }
                {!!responsabileDelProgetto &&
                  <div className="webcontent">
                    <h2 className="webcontent__title">Responsabile del Progetto</h2>
                    <div dangerouslySetInnerHTML={{ __html: responsabileDelProgetto.childMarkdownRemark.html }}>
                    </div>
                  </div>
                }
                 {!!altriEntiPartners &&
                  <div className="webcontent">
                    <h2 className="webcontent__title">Altri Enti Partners</h2>
                    <div dangerouslySetInnerHTML={{ __html: altriEntiPartners.childMarkdownRemark.html }}>
                    </div>
                  </div>
                }
               
              </div>
            </div>
          </article>  
        </div>
      </Layout>
    )
  }

}

export default ProgettoTemplate;

export const pageQuery = graphql`query ProgettoDetailQuery($contentful_id: String!) {
  contentfulProgetto(contentful_id: {eq: $contentful_id}) {
    id
    titolo
    friendlyUrl
    durataDelProgetto
    responsabileDelProgetto {
      childMarkdownRemark {
        html
      }
    }
    capofilaProgetto {
      childMarkdownRemark {
        html
      }
    }
    altriEntiPartners {
      childMarkdownRemark {
        html
      }
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
  }
  
}
`;
